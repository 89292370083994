import React, { useEffect, useState } from "react"
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from '@table-library/react-table-library/table';
import { Amplify, API } from 'aws-amplify'
import AWSConfig from './aws-exports'
import { FormControlLabel, TextField, Button, Dialog, Switch, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import './App.css';

const Reallocations = () => {
    const tableTheme = useTheme(getTheme());
    const [reallocations, setReallocations] = useState([])
    // eslint-disable-next-line
    const [showInactiveReallocations, setShowInactiveReallocations] = useState(false)
    const [search, setSearch] = useState('');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showNewReallocationDialog, setShowNewReallocationDialog] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState({})
    const data = {
        nodes: reallocations.filter(
            (item) =>
                item.name.toLowerCase().includes(search.toLowerCase()) &&
                (item.active === true || showInactiveReallocations)
        ),
    };

    const sort = useSort(data, {
        state: {
            sortKey: 'PRIORITY', reverse: false,
        },
    }, {
        sortFns: {
            NAME: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            PRIORITY: (array) => array.sort((a, b) => (a.priority - b.priority | 0)),
            ACTIVE: (array) => array.sort((a, b) => (a.active | false - b.active | false)),
        },
    });

    const fetchData = async () => {
        API.get('reallocations', 'reallocations', {})
            .then(response => {
                const data = response.reallocations;
                data.forEach((item, i) => {
                    item.id = i + 1;
                });
                setReallocations(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleRowClick = (item) => {
        setRecordToEdit(item);
        setShowEditDialog(true);
    };

    const handleNewReallocationClick = () => {
        setRecordToEdit({ 'active': true, 'type': 'reallocation' });
        setShowNewReallocationDialog(true);
    };

    const handleEditDialogSave = () => {
        setShowEditDialog(false);
        API.post('reallocations', 'reallocations', { body: recordToEdit })
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    const handleEditDialogCancel = () => {
        setShowEditDialog(false);
        setRecordToEdit({});
    };

    const handleNewReallocationDialogSave = () => {
        console.log("Creating new reallocation: " + JSON.stringify(recordToEdit));
        setShowNewReallocationDialog(false);
        setSearch(recordToEdit.name)

        API.post('reallocations', 'reallocations', { body: recordToEdit })
            .then(response => {
                fetchData();
            })
            .catch(error => {
                console.log(error.toString());
            });
    };

    const handleNewReallocationDialogCancel = () => {
        setShowNewReallocationDialog(false);
        setRecordToEdit({});
    };

    const handleReallocationUpdate = (event) => {
        let target = event.target.id;
        let val = event.target.value;
        if (event.target.type === 'number') {
            val = parseInt(val, 10)
        }
        setRecordToEdit(prevState => ({
            ...prevState,
            [target]: val
        }));
    }

    const handleToggleUpdate = (event) => {
        setRecordToEdit(prevState => ({
            ...prevState,
            [event.target.id]: !prevState[event.target.id]
        }));
    }

    const handleNewReallocationUpdate = (event) => {
        let target = event.target.id;
        let val = event.target.value;
        if (event.target.type === 'number') {
            val = parseInt(val, 10)
        }
        setRecordToEdit(prevState => ({
            ...prevState,
            [target]: val,
        }));
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const toggleShowInactiveReallocations = () => {
        setShowInactiveReallocations(!showInactiveReallocations)
    }

    useEffect(() => {
        Amplify.configure(AWSConfig)
        fetchData()
    }, [])

    return (<>
        <div>
            <table className="top-nav">
                <thead>
                    <tr>
                        <td className="top-nav-cell">
                            <label className="top-nav-button" htmlFor="search">
                                <b>Search by Reallocation:</b> <input id="search" type="text" onChange={handleSearch} value={search} />
                            </label>
                        </td>
                        <td className="top-nav-cell">
                            <Button className="top-nav-buttons" variant="contained" onClick={() => handleNewReallocationClick()}>
                                New Reallocation
                            </Button>
                        </td>
                        <td className="top-nav-cell">
                            <FormControlLabel label="Show inactive reallocations"
                                control={<Switch
                                    id="showInactiveReallocations"
                                    onChange={toggleShowInactiveReallocations}
                                    checked={showInactiveReallocations}
                                />}
                            />
                        </td>
                    </tr>
                </thead>
            </table>
            <Table className="main" data={data} sort={sort} theme={tableTheme} layout={{ fixedHeader: true }}>{(tableList) => (<>
                <Header>
                    <HeaderRow>
                        <HeaderCellSort sortKey="TYPE">Type</HeaderCellSort>
                        <HeaderCellSort sortKey="PRIORITY">Priority</HeaderCellSort>
                        <HeaderCellSort sortKey="NAME">Name</HeaderCellSort>
                        <HeaderCell>Query</HeaderCell>
                        <HeaderCell>Target Attribute</HeaderCell>
                        <HeaderCell>Target Value</HeaderCell>
                        <HeaderCellSort sortKey="ACTIVE">Active</HeaderCellSort>
                    </HeaderRow>
                </Header>
                <Body>
                    {tableList.map((item) => (
                        <Row key={item.id} item={item} onClick={(event) => handleRowClick(item)}>
                            <Cell>{item.type}</Cell>
                            <Cell>{item.priority}</Cell>
                            <Cell>{item.name}</Cell>
                            <Cell><code>{item.query}</code></Cell>
                            <Cell>{item.attribute_to_update}</Cell>
                            <Cell>{item.value_to_assign}</Cell>
                            <Cell>{item.active ? "true" : "false"}</Cell>
                        </Row>
                    ))}
                </Body>
            </>)}
            </Table>
        </div>
        <Dialog className="dialog" open={showEditDialog} onClose={handleEditDialogCancel}>
            <DialogTitle>Edit Reallocation: {recordToEdit.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.name}
                />
                <TextField onChange={handleReallocationUpdate}
                    margin="dense"
                    id="priority"
                    label="Priority"
                    type="number"
                    variant="standard"
                    defaultValue={recordToEdit.priority}
                />
                <TextField onBlur={handleReallocationUpdate}
                    sx={{
                        'textarea': {
                            fontFamily: 'monospace'
                        }
                    }}
                    multiline
                    minRows={4}
                    margin="dense"
                    id="query"
                    label="Query"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.query}
                />
                <TextField onBlur={handleReallocationUpdate}
                    margin="dense"
                    id="attribute_to_update"
                    label="Target Attribute"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.attribute_to_update}
                />
                <TextField onBlur={handleReallocationUpdate}
                    margin="dense"
                    id="value_to_assign"
                    label="Target Value"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.value_to_assign}
                />
                <FormControlLabel label="Active"
                    control={<Switch
                        margin="dense"
                        id="active"
                        onChange={handleToggleUpdate}
                        checked={recordToEdit.active}
                    />}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleEditDialogSave}>Save</Button>
                <Button onClick={handleEditDialogCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showNewReallocationDialog} onClose={handleNewReallocationDialogCancel}>
            <DialogTitle>New Reallocation: {recordToEdit.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="type"
                    label="Type"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.type}
                    disabled={true}
                />
                <TextField onBlur={handleNewReallocationUpdate}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.name}
                />
                <TextField onBlur={handleNewReallocationUpdate}
                    autoFocus
                    margin="dense"
                    id="priority"
                    label="Priority"
                    type="number"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.priority}
                />
                <TextField onBlur={handleNewReallocationUpdate}
                    sx={{
                        'textarea': {
                            fontFamily: 'monospace'
                        }
                    }}
                    multiline
                    minRows={4}
                    margin="dense"
                    id="query"
                    label="Query"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.query}
                />
                <TextField onBlur={handleNewReallocationUpdate}
                    autoFocus
                    margin="dense"
                    id="attribute_to_update"
                    label="Target Attribute"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.attribute_to_update}
                />
                <TextField onBlur={handleNewReallocationUpdate}
                    autoFocus
                    margin="dense"
                    id="value_to_assign"
                    label="Target Value"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.value_to_assign}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleNewReallocationDialogSave}>Save</Button>
                <Button onClick={handleNewReallocationDialogCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default Reallocations
