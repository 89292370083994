import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import UMassChanLogo from './UMassChan_Vertical_White.png';
import { Person, RequestPage, Biotech, CompareArrows } from '@mui/icons-material';
const NavBar = (props) => {
    const location = useLocation().pathname;
    const user = props.user;

    return (
        <>
            <AppBar position="static"
                sx={{
                    marginBottom: '1rem',
                }}>
                <Toolbar>
                    <Box>
                        <img className="logo" alt="UMass Chan logo" src={UMassChanLogo} />
                    </Box>
                    <Box flexGrow={1}>
                        <h1>AWS Billing</h1>
                    </Box>
                    <Box>
                        <Button
                            color="inherit"
                            component={Link}
                            to="/"
                            variant={location === "/" ? "outlined" : ""}
                        >
                            <RequestPage />
                            Reports
                        </Button>
                        <Button
                            color="inherit"
                            component={Link}
                            to="/projects"
                            variant={location === "/projects" ? "outlined" : ""}
                        >
                            <Biotech />
                            Projects
                        </Button>
                        <Button
                            color="inherit"
                            component={Link}
                            to="/reallocations"
                            variant={location === "/reallocations" ? "outlined" : ""}
                        >
                            <CompareArrows />
                            Reallocations
                        </Button>
                    </Box>
                    <Person />{user}
                    <Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavBar;
