import React, { useEffect, useState } from "react"
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from '@table-library/react-table-library/table';
import { Amplify, API } from 'aws-amplify'
import AWSConfig from './aws-exports'
import { FormControlLabel, TextField, Button, Dialog, Switch, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import './App.css';

const Projects = () => {
    const tableTheme = useTheme(getTheme());
    const [projects, setProjects] = useState([])
    const [showInactiveProjects, setShowInactiveProjects] = useState(false)
    const [search, setSearch] = useState('');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showNewProjectDialog, setShowNewProjectDialog] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState({})
    const data = {
        nodes: projects.filter(
            (item) =>
                item.project.toLowerCase().includes(search.toLowerCase()) &&
                (item.active === true || showInactiveProjects)
        ),
    };

    const sort = useSort(data, {
        state: {
            sortKey: 'PROJECT', reverse: false,
        },
    }, {
        sortFns: {
            PROJECT: (array) => array.sort((a, b) => a.project.localeCompare(b.project)),
            // SPEEDTYPE: (array) => array.sort((a, b) => a.speedtype.toString().localeCompare(b.project.toString())),
            TECHNICAL_CONTACT: (array) => array.sort((a, b) => a.technical_contact.localeCompare(b.technical_contact)),
            ACTIVE: (array) => array.sort((a, b) => (a.active | false - b.active | false)),
            SEND_MONTHLY_REPORTS: (array) => array.sort((a, b) => (a.reports | false - b.reports | false)),
        },
    });

    const fetchData = async () => {
        API.get('projects', 'projects', {})
            .then(response => {
                const data = response.projects;
                data.forEach((item, i) => {
                    item.id = i + 1;
                });
                setProjects(data);
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    const handleRowClick = (item) => {
        setRecordToEdit(item);
        setShowEditDialog(true);
    };

    const handleNewProjectClick = () => {
        setRecordToEdit({ 'active': true, 'reports': false });
        setShowNewProjectDialog(true);
    };

    const handleEditDialogSave = () => {
        setShowEditDialog(false);
        API.post('projects', 'projects', { body: recordToEdit })
            .then(() => {
                fetchData();
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    const handleEditDialogCancel = () => {
        setShowEditDialog(false);
        setRecordToEdit({});
    };

    const handleNewProjectDialogSave = () => {
        setShowNewProjectDialog(false);
        setSearch(recordToEdit.project)
        API.post('projects', 'projects', { body: recordToEdit })
            .then(() => {
                fetchData();
            })
            .catch(error => {
                console.log(error.toString());
            });
    };

    const handleNewProjectDialogCancel = () => {
        setShowNewProjectDialog(false);
        setRecordToEdit({});
    };

    const handleProjectUpdate = (event) => {
        setRecordToEdit(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    const handleToggleUpdate = (event) => {
        setRecordToEdit(prevState => ({
            ...prevState,
            [event.target.id]: !prevState[event.target.id]
        }));
    }

    const handleNewProjectUpdate = (event) => {
        console.log(JSON.stringify(recordToEdit));
        setRecordToEdit(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value,
        }));
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const toggleShowInactiveProjects = () => {
        setShowInactiveProjects(!showInactiveProjects)
    }

    useEffect(() => {
        Amplify.configure(AWSConfig)
        fetchData()
    }, [])

    return (<>
        <div>
            <table className="top-nav">
                <thead>
                    <tr>
                        <td className="top-nav-cell">
                            <label className="top-nav-button" htmlFor="search">
                                <b>Search by Project:</b> <input id="search" type="text" onChange={handleSearch} />
                            </label>
                        </td>
                        <td className="top-nav-cell">
                            <Button className="top-nav-buttons" variant="contained" onClick={() => handleNewProjectClick()}>
                                New Project
                            </Button>
                        </td>
                        <td className="top-nav-cell">
                            <FormControlLabel label="Show inactive projects"
                                control={<Switch
                                    id="showInactiveProjects"
                                    onChange={toggleShowInactiveProjects}
                                    checked={showInactiveProjects}
                                />}
                            />
                        </td>
                    </tr>
                </thead>
            </table>
            <Table className="main" data={data} sort={sort} theme={tableTheme} layout={{ fixedHeader: true }}>{(tableList) => (<>
                <Header>
                    <HeaderRow>
                        <HeaderCellSort sortKey="PROJECT">Project</HeaderCellSort>
                        {/*<HeaderCellSort sortKey="SPEEDTYPE">SpeedType</HeaderCellSort>*/}
                        <HeaderCell>SpeedType</HeaderCell>
                        <HeaderCellSort sortKey="TECHNICAL_CONTACT">Technical Support Contact</HeaderCellSort>
                        <HeaderCellSort sortKey="ACTIVE">Active</HeaderCellSort>
                        <HeaderCellSort sortKey="SEND_MONTHLY_REPORTS">Send Monthly Reports</HeaderCellSort>
                    </HeaderRow>
                </Header>
                <Body>
                    {tableList.map((item) => (
                        <Row key={item.id} item={item} onClick={(event) => handleRowClick(item)}>
                            <Cell>{item.project}</Cell>
                            <Cell>{item.speedtype}</Cell>
                            <Cell>{item.technical_contact}</Cell>
                            <Cell>{item.active ? "true" : "false"}</Cell>
                            <Cell>{item.reports ? "true" : "false"}</Cell>
                        </Row>
                    ))}
                </Body>
            </>)}
            </Table>
        </div>
        <Dialog className="dialog" open={showEditDialog} onClose={handleEditDialogCancel}>
            <DialogTitle>Edit Project: {recordToEdit.project}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="project"
                    label="Project"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.project}
                    disabled={true}
                />
                <TextField onBlur={handleProjectUpdate}
                    margin="dense"
                    id="speedtype"
                    label="Speedtype"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.speedtype}
                />
                <TextField onBlur={handleProjectUpdate}
                    margin="dense"
                    id="technical_contact"
                    label="Technical Support Contact"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.technical_contact}
                />

                <FormControlLabel label="Active"
                    control={<Switch
                        id="active"
                        onChange={handleToggleUpdate}
                        checked={recordToEdit.active}
                    />}
                />

                <FormControlLabel label="Send Monthly Reports"
                    control={<Switch
                        id="reports"
                        onChange={handleToggleUpdate}
                        checked={recordToEdit.reports}
                    />}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleEditDialogSave}>Save</Button>
                <Button onClick={handleEditDialogCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showNewProjectDialog} onClose={handleNewProjectDialogCancel}>
            <DialogTitle>New Project: {recordToEdit.project}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField onBlur={handleNewProjectUpdate}
                    autoFocus
                    margin="dense"
                    id="project"
                    label="Project"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.project}
                />
                <TextField onBlur={handleNewProjectUpdate}
                    autoFocus
                    margin="dense"
                    id="speedtype"
                    label="Speedtype"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.speedtype}
                />
                <TextField onBlur={handleNewProjectUpdate}
                    autoFocus
                    margin="dense"
                    id="technical_contact"
                    label="Technical Contact"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={recordToEdit.techical_contact}
                />
                <FormControlLabel label="Active"
                    control={<Switch
                        id="active"
                        onChange={handleToggleUpdate}
                        checked={recordToEdit.active}
                    />}
                />

                <FormControlLabel label="Send Monthly Reports"
                    control={<Switch
                        id="reports"
                        onChange={handleToggleUpdate}
                        checked={recordToEdit.reports}
                    />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNewProjectDialogSave}>Save</Button>
                <Button onClick={handleNewProjectDialogCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default Projects
