import Reports from './Reports';
import Projects from './Projects';
import Reallocations from './Reallocations';

const routes = [
  { path: '/', component: <Reports />, exact: true },
  { path: '/projects', component: <Projects /> },
  { path: '/reallocations', component: <Reallocations /> },
];

export default routes;
