import React, { useEffect, useState } from "react"
import './App.css';
import Nav from './Nav.js';
import routes from './routes.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Amplify, Auth, Hub } from 'aws-amplify'
import AWSConfig from './aws-exports'

const App = () => {
    const [user, setUser] = useState(false)
    useEffect(() => {
        Amplify.configure(AWSConfig)
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    // console.log('sign in', event, data)
                    break
                case 'signOut':
                    // console.log('sign out')
                    setUser({ user: null })
                    break
                default:
                    // console.log("ignoring auth event: " + event)
                    break

            }
        })


        Auth.currentSession().then(res => {
            let token = res.getIdToken()
            setUser(token.payload.email)
        }).catch(() => {
            console.log('Not signed in, redirecting to login')
            Auth.federatedSignIn()
        })
    }, [])

    return (
        <BrowserRouter>
            <Nav
                user={user}
            />
            {user && <div>
                <Routes>
                    {routes.map((route, index) => (
                        <Route key={index}
                            path={route.path}
                            element={route.component}
                        />
                    ))}
                </Routes>
            </div>}
        </BrowserRouter>
    )
};

export default App;
