import { Auth } from "aws-amplify";
const AWSConfig = {
    Auth: {
        // // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_identityPoolId,

        // // (required)- Amazon Cognito Region
        region: process.env.REACT_APP_region,

        // (optional) - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // (optional) - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_userPoolId,

        // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
        userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,

        // (optional) - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // (optional) - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // - Cookie domain (only required if cookieStorage is provided)
            domain: process.env.REACT_APP_cookieDomain,
            // (optional) - Cookie path
            path: '/',
            // (optional) - Cookie expiration in days
            expires: 365,
            // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: 'strict',
            // | 'lax',
            // (optional) - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },

        // (optional) - customized storage object
        // storage: MyStorage,

        // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // (optional) - Hosted UI configuration
        oauth: {
            domain: process.env.REACT_APP_oauthDomain,
            scope: [
                'phone',
                'email',
                'profile',
                'openid',
                // 'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: process.env.REACT_APP_redirectSignIn,
            redirectSignOut: process.env.REACT_APP_redirectSignOut,
            clientId: process.env.REACT_APP_userPoolWebClientId,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    API: {
        endpoints: [
            {
                name: "projects",
                endpoint: process.env.REACT_APP_apiEndpoint,
                custom_header: async () => {
                    // return {Authorization: 'token'}
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "reallocations",
                endpoint: process.env.REACT_APP_apiEndpoint,
                custom_header: async () => {
                    // return {Authorization: 'token'}
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "reports",
                endpoint: process.env.REACT_APP_apiEndpoint,
                custom_header: async () => {
                    // return {Authorization: 'token'}
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
}

export default AWSConfig;