import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js'
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

root.render(
    <React.StrictMode>
        <CssBaseline theme={darkTheme}>
            <App />
        </CssBaseline>
    </React.StrictMode>
);

