import React, { useEffect, useState } from "react"
import { Amplify, API } from 'aws-amplify'
import AWSConfig from './aws-exports'
import { InputLabel, MenuItem, FormControl, Select, Button, Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import './App.css'

const Reports = () => {
    const [months, setMonths] = useState([])
    const [month, setMonth] = React.useState('');
    const [reportType, setReportType] = useState('summary')

    const handleMonthSelect = (event) => {
        setMonth(event.target.value);
    };

    const handleReportTypeToggle = (event) => {
        setReportType(event.target.value);
    };

    const getAvailableReports = (event) => {
        API.get('reports', 'reports', {}).then(response => {
            setMonths(response.months)
            setMonth(response.months[0])
        }).catch(error => {
            console.log(error.response);
        });
    }

    const handleDownloadReport = (event) => {
        console.log("downloading report for " + month)
        API.get('reports', 'reports', {
            queryStringParameters: {
                month: month,
                type: reportType
            }
        }).then(response => {
            console.log(response)
            window.open(response.presigned_url, 'noreferrer')
        }).catch(error => {
            console.log(error.response);
        });
    }

    useEffect(() => {
        Amplify.configure(AWSConfig)
        getAvailableReports()
    }, [])

    return (<>
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
            <FormControl sx={{ minWidth: 120, color: 'white' }}>
                <InputLabel sx={{ color: 'white' }}>Month</InputLabel>
                <Select
                    id="month"
                    value={month}
                    onChange={handleMonthSelect}
                    label="Month"
                    sx={{
                        color: 'white'
                    }}
                >
                    {months.map((month) => (
                        <MenuItem value={month}>{month}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ToggleButtonGroup
                sx={{
                    m: 2,
                    minWidth: 120,
                }}
                color='primary'
                value={reportType}
                exclusive
                onChange={handleReportTypeToggle}
            >
                <ToggleButton
                    sx={{
                        color: 'white',
                        borderColor: 'white',
                        "&.Mui-selected": {
                            color: 'white',
                            backgroundColor: 'primary.main'
                        }
                    }}
                    value="summary">
                    Summary
                </ToggleButton>
                <ToggleButton
                    sx={{
                        color: 'white',
                        borderColor: 'white',
                        "&.Mui-selected": {
                            color: 'white',
                            backgroundColor: 'primary.main'
                        }
                    }}
                    value="dbr">
                    DBR
                </ToggleButton>
            </ToggleButtonGroup>
            <FormControl>
                <Button
                    variant="contained"
                    margin="dense"
                    onClick={handleDownloadReport}
                >
                    Download Report
                </Button>
            </FormControl>
        </Box>
    </>
    )
}

export default Reports
